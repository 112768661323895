import plugin_vue3_A0OWXRrUgq from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import templates_pwa_client_15bedddc_j9xTpfLwQp from "/vercel/path0/.nuxt/templates.pwa.client.15bedddc.ts";
import plugin_8SbxDRbG6Y from "/vercel/path0/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import plugin_client_i8AMfKeYnY from "/vercel/path0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/vercel/path0/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import ahoy_client_HtNXopC4Jh from "/vercel/path0/plugins/ahoy.client.js";
import clipboard_un1pNMmI0D from "/vercel/path0/plugins/clipboard.js";
import filters_znakZuvLqA from "/vercel/path0/plugins/filters.js";
import formbricks_client_wpRDwqv1fj from "/vercel/path0/plugins/formbricks.client.js";
import maska_r8BHBbSmHt from "/vercel/path0/plugins/maska.js";
import posthog_client_o9IEgU9LwG from "/vercel/path0/plugins/posthog.client.js";
import vue3_toastify_OGYNDsiW9E from "/vercel/path0/plugins/vue3-toastify.ts";
import vuedatepicker_CrkHxJMSd1 from "/vercel/path0/plugins/vuedatepicker.js";
export default [
  plugin_vue3_A0OWXRrUgq,
  revive_payload_client_4sVQNw7RlN,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  templates_pwa_client_15bedddc_j9xTpfLwQp,
  plugin_8SbxDRbG6Y,
  plugin_client_i8AMfKeYnY,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  ahoy_client_HtNXopC4Jh,
  clipboard_un1pNMmI0D,
  filters_znakZuvLqA,
  formbricks_client_wpRDwqv1fj,
  maska_r8BHBbSmHt,
  posthog_client_o9IEgU9LwG,
  vue3_toastify_OGYNDsiW9E,
  vuedatepicker_CrkHxJMSd1
]