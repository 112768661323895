import { useUserStore } from '@/store/user.js'
import { useMainStore } from '@/store/root.js'

import { storeToRefs } from 'pinia'

export default defineNuxtRouteMiddleware((to, from) => {
  // isAuthenticated() is an example method verifying if a user is authenticated
  // console.log('opa => ', to, useNuxtApp())

  const userStore = useUserStore()
  const { loggedIn: isLoggedIn } = storeToRefs(userStore)
  // , currentUser, currentProducer, isProducer, token
  // console.log('oieeee -> ', isLoggedIn.value, currentUser.value, currentProducer.value, isProducer.value, token.value)

  if(!isLoggedIn.value && to.fullPath !== '/entrar') {
    return navigateTo('/entrar')
  }
  else if (isLoggedIn.value && ['/entrar', '/'].includes(to.fullPath)) {
    return navigateTo('/meus_eventos')
  }

  // if (isAuthenticated() === false) {
  //
  // }
})
