<template>
  <div>
    <VitePwaManifest />
    <NuxtLoadingIndicator :height="1" color="repeating-linear-gradient(to right,#ff64a0 0%, #fa64ff 50%,#fe62a1 100%)" />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <StagingAlert v-if="isStaging" />
  </div>
</template>

<script setup>

// detects if is on staging (should be app.pixta.pro)
const isStaging = ref(false)
onMounted(() => {
  if (window.location.host === 'app.pixta.pro') isStaging.value = true
})

useHead({
  titleTemplate: (title) => !title ? 'PIXTA.me' : `${title} | PIXTA.me`,
  description: 'Compre e venda ingressos para seus eventos, por pix e cartão, com agilidade e segurança', // max 155 / 200
  htmlAttrs: {
    lang: 'pt-BR'
  },
  meta: [
    { charset: 'utf-8' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' }
  ],
  bodyAttrs: {
    // class: 'dark'
  },
  link: [
    // { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' },
    // { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700;900&display=swap' },
    // { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
    // { rel: 'preconnect', href: 'https://fonts.gstatic.com', crossorigin: '' }
  ],
  script: [
    // { src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAixflCc-p8uJO-eGleMs4QPBpFcIptBGc&libraries=places' }
  ]
})
</script>
