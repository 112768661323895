import { default as extrato2IT9N36iPtMeta } from "/vercel/path0/pages/carteira/extrato.vue?macro=true";
import { default as indexP8SdDIXJZcMeta } from "/vercel/path0/pages/carteira/index.vue?macro=true";
import { default as carteiraBJsJpw7M5dMeta } from "/vercel/path0/pages/carteira.vue?macro=true";
import { default as indextvhAZ7Kq3ZMeta } from "/vercel/path0/pages/central-de-ajuda/index.vue?macro=true";
import { default as como_45funcionaMCnCkGn0OYMeta } from "/vercel/path0/pages/como-funciona.vue?macro=true";
import { default as contaRNCjikNgMWMeta } from "/vercel/path0/pages/conta.vue?macro=true";
import { default as crie_45seu_45eventoU6FBsWx0yhMeta } from "/vercel/path0/pages/crie-seu-evento.vue?macro=true";
import { default as entrark4vXiRcpiKMeta } from "/vercel/path0/pages/entrar.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as analyticsV9IX2MYXzYMeta } from "/vercel/path0/pages/meus_eventos/[slug]/analytics.vue?macro=true";
import { default as beta7Ik34kbgC6Meta } from "/vercel/path0/pages/meus_eventos/[slug]/beta.vue?macro=true";
import { default as index0YDBJp67EyMeta } from "/vercel/path0/pages/meus_eventos/[slug]/checkin/index.vue?macro=true";
import { default as _91couponId_93oWfhLmxS4eMeta } from "/vercel/path0/pages/meus_eventos/[slug]/coupons/[couponId].vue?macro=true";
import { default as indexEUWijc7481Meta } from "/vercel/path0/pages/meus_eventos/[slug]/coupons/index.vue?macro=true";
import { default as editIBuxpXU8KAMeta } from "/vercel/path0/pages/meus_eventos/[slug]/edit.vue?macro=true";
import { default as _91fieldSlug_93WgkLwvYxEeMeta } from "/vercel/path0/pages/meus_eventos/[slug]/editar/[fieldSlug].vue?macro=true";
import { default as index9yNybDSUwiMeta } from "/vercel/path0/pages/meus_eventos/[slug]/editar/index.vue?macro=true";
import { default as editar1obKJDPaArMeta } from "/vercel/path0/pages/meus_eventos/[slug]/editar.vue?macro=true";
import { default as galeriaw1OYQNMa4JMeta } from "/vercel/path0/pages/meus_eventos/[slug]/galeria.vue?macro=true";
import { default as indexAt67QLO1ggMeta } from "/vercel/path0/pages/meus_eventos/[slug]/index.vue?macro=true";
import { default as _91orderId_93GujfaWNEO4Meta } from "/vercel/path0/pages/meus_eventos/[slug]/orders/[orderId].vue?macro=true";
import { default as indexjhqQy1r6VFMeta } from "/vercel/path0/pages/meus_eventos/[slug]/orders/index.vue?macro=true";
import { default as configuracaoZPLWc3dWz7Meta } from "/vercel/path0/pages/meus_eventos/[slug]/partners/configuracao.vue?macro=true";
import { default as indexPtPqH6LzkPMeta } from "/vercel/path0/pages/meus_eventos/[slug]/partners/index.vue?macro=true";
import { default as _91productSlug_93Annm4HtgpYMeta } from "/vercel/path0/pages/meus_eventos/[slug]/products/[productSlug].vue?macro=true";
import { default as indexiaThdxfW4SMeta } from "/vercel/path0/pages/meus_eventos/[slug]/products/index.vue?macro=true";
import { default as qrcodesMndRvGB2XUMeta } from "/vercel/path0/pages/meus_eventos/[slug]/qrcodes.vue?macro=true";
import { default as report0xktDwAMWnMeta } from "/vercel/path0/pages/meus_eventos/[slug]/report.vue?macro=true";
import { default as indexohp5RAYthfMeta } from "/vercel/path0/pages/meus_eventos/[slug]/tickets/[productId]/index.vue?macro=true";
import { default as inviteszrKujT8Fg1Meta } from "/vercel/path0/pages/meus_eventos/[slug]/tickets/[productId]/invites.vue?macro=true";
import { default as index8PWx9NnvZCMeta } from "/vercel/path0/pages/meus_eventos/[slug]/tickets/index.vue?macro=true";
import { default as indexpoh90Q6U4vMeta } from "/vercel/path0/pages/meus_eventos/[slug]/users/index.vue?macro=true";
import { default as venueoiqz1uUXRJMeta } from "/vercel/path0/pages/meus_eventos/[slug]/venue.vue?macro=true";
import { default as _91slug_93NBPPW4jnsEMeta } from "/vercel/path0/pages/meus_eventos/[slug].vue?macro=true";
import { default as indexEshe4L3i94Meta } from "/vercel/path0/pages/meus_eventos/index.vue?macro=true";
import { default as my_accountR2VGeWjWHNMeta } from "/vercel/path0/pages/my_account.vue?macro=true";
import { default as indexH5AaUUeSy6Meta } from "/vercel/path0/pages/onboarding/index.vue?macro=true";
import { default as editmoi9t0929fMeta } from "/vercel/path0/pages/p/[slug]/campaigns/[campaignId]/edit.vue?macro=true";
import { default as index6fcOqsrXuFMeta } from "/vercel/path0/pages/p/[slug]/campaigns/[campaignId]/index.vue?macro=true";
import { default as send7wDVBgKFIlMeta } from "/vercel/path0/pages/p/[slug]/campaigns/[campaignId]/send.vue?macro=true";
import { default as indexljmFmtnJqIMeta } from "/vercel/path0/pages/p/[slug]/campaigns/index.vue?macro=true";
import { default as new9Bb5FdVE4MMeta } from "/vercel/path0/pages/p/[slug]/campaigns/new.vue?macro=true";
import { default as campaignsDfCQHEsYkPMeta } from "/vercel/path0/pages/p/[slug]/campaigns.vue?macro=true";
import { default as _91contactListSlug_93lWDlr6Gd2mMeta } from "/vercel/path0/pages/p/[slug]/contact_lists/[contactListSlug].vue?macro=true";
import { default as indexUMVFZD2I04Meta } from "/vercel/path0/pages/p/[slug]/contact_lists/index.vue?macro=true";
import { default as editXIVbAO1LNGMeta } from "/vercel/path0/pages/p/[slug]/edit.vue?macro=true";
import { default as editCxWmwn5ZkuMeta } from "/vercel/path0/pages/p/[slug]/guestlists/[guestlistId]/edit.vue?macro=true";
import { default as indexsL6R3wPZORMeta } from "/vercel/path0/pages/p/[slug]/guestlists/[guestlistId]/index.vue?macro=true";
import { default as indexDyHHDt0pBNMeta } from "/vercel/path0/pages/p/[slug]/guestlists/index.vue?macro=true";
import { default as new3LWjwTB37pMeta } from "/vercel/path0/pages/p/[slug]/guestlists/new.vue?macro=true";
import { default as guestlistsfTSphu4R1VMeta } from "/vercel/path0/pages/p/[slug]/guestlists.vue?macro=true";
import { default as indextg33DI1USAMeta } from "/vercel/path0/pages/p/[slug]/index.vue?macro=true";
import { default as _91guestlistId_93dnnBRI1DKFMeta } from "/vercel/path0/pages/p/[slug]/listas/[guestlistId].vue?macro=true";
import { default as indexZETROZKIcTMeta } from "/vercel/path0/pages/producer_wizard/index.vue?macro=true";
import { default as new3aF4rqkogXMeta } from "/vercel/path0/pages/producer_wizard/new.vue?macro=true";
import { default as _91slug_93OsOWxVXvk9Meta } from "/vercel/path0/pages/producer_wizard/success/[slug].vue?macro=true";
export default [
  {
    path: carteiraBJsJpw7M5dMeta?.path ?? "/carteira",
    children: [
  {
    name: extrato2IT9N36iPtMeta?.name ?? "carteira-extrato",
    path: extrato2IT9N36iPtMeta?.path ?? "extrato",
    meta: extrato2IT9N36iPtMeta || {},
    alias: extrato2IT9N36iPtMeta?.alias || [],
    redirect: extrato2IT9N36iPtMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/carteira/extrato.vue").then(m => m.default || m)
  },
  {
    name: indexP8SdDIXJZcMeta?.name ?? "carteira",
    path: indexP8SdDIXJZcMeta?.path ?? "",
    meta: indexP8SdDIXJZcMeta || {},
    alias: indexP8SdDIXJZcMeta?.alias || [],
    redirect: indexP8SdDIXJZcMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/carteira/index.vue").then(m => m.default || m)
  }
],
    name: carteiraBJsJpw7M5dMeta?.name ?? undefined,
    meta: carteiraBJsJpw7M5dMeta || {},
    alias: carteiraBJsJpw7M5dMeta?.alias || [],
    redirect: carteiraBJsJpw7M5dMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/carteira.vue").then(m => m.default || m)
  },
  {
    name: indextvhAZ7Kq3ZMeta?.name ?? "central-de-ajuda",
    path: indextvhAZ7Kq3ZMeta?.path ?? "/central-de-ajuda",
    meta: indextvhAZ7Kq3ZMeta || {},
    alias: indextvhAZ7Kq3ZMeta?.alias || [],
    redirect: indextvhAZ7Kq3ZMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/central-de-ajuda/index.vue").then(m => m.default || m)
  },
  {
    name: como_45funcionaMCnCkGn0OYMeta?.name ?? "como-funciona",
    path: como_45funcionaMCnCkGn0OYMeta?.path ?? "/como-funciona",
    meta: como_45funcionaMCnCkGn0OYMeta || {},
    alias: como_45funcionaMCnCkGn0OYMeta?.alias || [],
    redirect: como_45funcionaMCnCkGn0OYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/como-funciona.vue").then(m => m.default || m)
  },
  {
    name: contaRNCjikNgMWMeta?.name ?? "conta",
    path: contaRNCjikNgMWMeta?.path ?? "/conta",
    meta: contaRNCjikNgMWMeta || {},
    alias: contaRNCjikNgMWMeta?.alias || [],
    redirect: contaRNCjikNgMWMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/conta.vue").then(m => m.default || m)
  },
  {
    name: crie_45seu_45eventoU6FBsWx0yhMeta?.name ?? "crie-seu-evento",
    path: crie_45seu_45eventoU6FBsWx0yhMeta?.path ?? "/crie-seu-evento",
    meta: crie_45seu_45eventoU6FBsWx0yhMeta || {},
    alias: crie_45seu_45eventoU6FBsWx0yhMeta?.alias || [],
    redirect: crie_45seu_45eventoU6FBsWx0yhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/crie-seu-evento.vue").then(m => m.default || m)
  },
  {
    name: entrark4vXiRcpiKMeta?.name ?? "entrar",
    path: entrark4vXiRcpiKMeta?.path ?? "/entrar",
    meta: entrark4vXiRcpiKMeta || {},
    alias: entrark4vXiRcpiKMeta?.alias || [],
    redirect: entrark4vXiRcpiKMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/entrar.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    path: _91slug_93NBPPW4jnsEMeta?.path ?? "/meus_eventos/:slug()",
    children: [
  {
    name: analyticsV9IX2MYXzYMeta?.name ?? "meus_eventos-slug-analytics",
    path: analyticsV9IX2MYXzYMeta?.path ?? "analytics",
    meta: analyticsV9IX2MYXzYMeta || {},
    alias: analyticsV9IX2MYXzYMeta?.alias || [],
    redirect: analyticsV9IX2MYXzYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/meus_eventos/[slug]/analytics.vue").then(m => m.default || m)
  },
  {
    name: beta7Ik34kbgC6Meta?.name ?? "meus_eventos-slug-beta",
    path: beta7Ik34kbgC6Meta?.path ?? "beta",
    meta: beta7Ik34kbgC6Meta || {},
    alias: beta7Ik34kbgC6Meta?.alias || [],
    redirect: beta7Ik34kbgC6Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/meus_eventos/[slug]/beta.vue").then(m => m.default || m)
  },
  {
    name: index0YDBJp67EyMeta?.name ?? "meus_eventos-slug-checkin",
    path: index0YDBJp67EyMeta?.path ?? "checkin",
    meta: index0YDBJp67EyMeta || {},
    alias: index0YDBJp67EyMeta?.alias || [],
    redirect: index0YDBJp67EyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/meus_eventos/[slug]/checkin/index.vue").then(m => m.default || m)
  },
  {
    name: _91couponId_93oWfhLmxS4eMeta?.name ?? "meus_eventos-slug-coupons-couponId",
    path: _91couponId_93oWfhLmxS4eMeta?.path ?? "coupons/:couponId()",
    meta: _91couponId_93oWfhLmxS4eMeta || {},
    alias: _91couponId_93oWfhLmxS4eMeta?.alias || [],
    redirect: _91couponId_93oWfhLmxS4eMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/meus_eventos/[slug]/coupons/[couponId].vue").then(m => m.default || m)
  },
  {
    name: indexEUWijc7481Meta?.name ?? "meus_eventos-slug-coupons",
    path: indexEUWijc7481Meta?.path ?? "coupons",
    meta: indexEUWijc7481Meta || {},
    alias: indexEUWijc7481Meta?.alias || [],
    redirect: indexEUWijc7481Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/meus_eventos/[slug]/coupons/index.vue").then(m => m.default || m)
  },
  {
    name: editIBuxpXU8KAMeta?.name ?? "meus_eventos-slug-edit",
    path: editIBuxpXU8KAMeta?.path ?? "edit",
    meta: editIBuxpXU8KAMeta || {},
    alias: editIBuxpXU8KAMeta?.alias || [],
    redirect: editIBuxpXU8KAMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/meus_eventos/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    path: editar1obKJDPaArMeta?.path ?? "editar",
    children: [
  {
    name: _91fieldSlug_93WgkLwvYxEeMeta?.name ?? "meus_eventos-slug-editar-fieldSlug",
    path: _91fieldSlug_93WgkLwvYxEeMeta?.path ?? ":fieldSlug()",
    meta: _91fieldSlug_93WgkLwvYxEeMeta || {},
    alias: _91fieldSlug_93WgkLwvYxEeMeta?.alias || [],
    redirect: _91fieldSlug_93WgkLwvYxEeMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/meus_eventos/[slug]/editar/[fieldSlug].vue").then(m => m.default || m)
  },
  {
    name: index9yNybDSUwiMeta?.name ?? "meus_eventos-slug-editar",
    path: index9yNybDSUwiMeta?.path ?? "",
    meta: index9yNybDSUwiMeta || {},
    alias: index9yNybDSUwiMeta?.alias || [],
    redirect: index9yNybDSUwiMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/meus_eventos/[slug]/editar/index.vue").then(m => m.default || m)
  }
],
    name: editar1obKJDPaArMeta?.name ?? undefined,
    meta: editar1obKJDPaArMeta || {},
    alias: editar1obKJDPaArMeta?.alias || [],
    redirect: editar1obKJDPaArMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/meus_eventos/[slug]/editar.vue").then(m => m.default || m)
  },
  {
    name: galeriaw1OYQNMa4JMeta?.name ?? "meus_eventos-slug-galeria",
    path: galeriaw1OYQNMa4JMeta?.path ?? "galeria",
    meta: galeriaw1OYQNMa4JMeta || {},
    alias: galeriaw1OYQNMa4JMeta?.alias || [],
    redirect: galeriaw1OYQNMa4JMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/meus_eventos/[slug]/galeria.vue").then(m => m.default || m)
  },
  {
    name: indexAt67QLO1ggMeta?.name ?? "meus_eventos-slug",
    path: indexAt67QLO1ggMeta?.path ?? "",
    meta: indexAt67QLO1ggMeta || {},
    alias: indexAt67QLO1ggMeta?.alias || [],
    redirect: indexAt67QLO1ggMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/meus_eventos/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: _91orderId_93GujfaWNEO4Meta?.name ?? "meus_eventos-slug-orders-orderId",
    path: _91orderId_93GujfaWNEO4Meta?.path ?? "orders/:orderId()",
    meta: _91orderId_93GujfaWNEO4Meta || {},
    alias: _91orderId_93GujfaWNEO4Meta?.alias || [],
    redirect: _91orderId_93GujfaWNEO4Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/meus_eventos/[slug]/orders/[orderId].vue").then(m => m.default || m)
  },
  {
    name: indexjhqQy1r6VFMeta?.name ?? "meus_eventos-slug-orders",
    path: indexjhqQy1r6VFMeta?.path ?? "orders",
    meta: indexjhqQy1r6VFMeta || {},
    alias: indexjhqQy1r6VFMeta?.alias || [],
    redirect: indexjhqQy1r6VFMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/meus_eventos/[slug]/orders/index.vue").then(m => m.default || m)
  },
  {
    name: configuracaoZPLWc3dWz7Meta?.name ?? "meus_eventos-slug-partners-configuracao",
    path: configuracaoZPLWc3dWz7Meta?.path ?? "partners/configuracao",
    meta: configuracaoZPLWc3dWz7Meta || {},
    alias: configuracaoZPLWc3dWz7Meta?.alias || [],
    redirect: configuracaoZPLWc3dWz7Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/meus_eventos/[slug]/partners/configuracao.vue").then(m => m.default || m)
  },
  {
    name: indexPtPqH6LzkPMeta?.name ?? "meus_eventos-slug-partners",
    path: indexPtPqH6LzkPMeta?.path ?? "partners",
    meta: indexPtPqH6LzkPMeta || {},
    alias: indexPtPqH6LzkPMeta?.alias || [],
    redirect: indexPtPqH6LzkPMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/meus_eventos/[slug]/partners/index.vue").then(m => m.default || m)
  },
  {
    name: _91productSlug_93Annm4HtgpYMeta?.name ?? "meus_eventos-slug-products-productSlug",
    path: _91productSlug_93Annm4HtgpYMeta?.path ?? "products/:productSlug()",
    meta: _91productSlug_93Annm4HtgpYMeta || {},
    alias: _91productSlug_93Annm4HtgpYMeta?.alias || [],
    redirect: _91productSlug_93Annm4HtgpYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/meus_eventos/[slug]/products/[productSlug].vue").then(m => m.default || m)
  },
  {
    name: indexiaThdxfW4SMeta?.name ?? "meus_eventos-slug-products",
    path: indexiaThdxfW4SMeta?.path ?? "products",
    meta: indexiaThdxfW4SMeta || {},
    alias: indexiaThdxfW4SMeta?.alias || [],
    redirect: indexiaThdxfW4SMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/meus_eventos/[slug]/products/index.vue").then(m => m.default || m)
  },
  {
    name: qrcodesMndRvGB2XUMeta?.name ?? "meus_eventos-slug-qrcodes",
    path: qrcodesMndRvGB2XUMeta?.path ?? "qrcodes",
    meta: qrcodesMndRvGB2XUMeta || {},
    alias: qrcodesMndRvGB2XUMeta?.alias || [],
    redirect: qrcodesMndRvGB2XUMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/meus_eventos/[slug]/qrcodes.vue").then(m => m.default || m)
  },
  {
    name: report0xktDwAMWnMeta?.name ?? "meus_eventos-slug-report",
    path: report0xktDwAMWnMeta?.path ?? "report",
    meta: report0xktDwAMWnMeta || {},
    alias: report0xktDwAMWnMeta?.alias || [],
    redirect: report0xktDwAMWnMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/meus_eventos/[slug]/report.vue").then(m => m.default || m)
  },
  {
    name: indexohp5RAYthfMeta?.name ?? "meus_eventos-slug-tickets-productId",
    path: indexohp5RAYthfMeta?.path ?? "tickets/:productId()",
    meta: indexohp5RAYthfMeta || {},
    alias: indexohp5RAYthfMeta?.alias || [],
    redirect: indexohp5RAYthfMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/meus_eventos/[slug]/tickets/[productId]/index.vue").then(m => m.default || m)
  },
  {
    name: inviteszrKujT8Fg1Meta?.name ?? "meus_eventos-slug-tickets-productId-invites",
    path: inviteszrKujT8Fg1Meta?.path ?? "tickets/:productId()/invites",
    meta: inviteszrKujT8Fg1Meta || {},
    alias: inviteszrKujT8Fg1Meta?.alias || [],
    redirect: inviteszrKujT8Fg1Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/meus_eventos/[slug]/tickets/[productId]/invites.vue").then(m => m.default || m)
  },
  {
    name: index8PWx9NnvZCMeta?.name ?? "meus_eventos-slug-tickets",
    path: index8PWx9NnvZCMeta?.path ?? "tickets",
    meta: index8PWx9NnvZCMeta || {},
    alias: index8PWx9NnvZCMeta?.alias || [],
    redirect: index8PWx9NnvZCMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/meus_eventos/[slug]/tickets/index.vue").then(m => m.default || m)
  },
  {
    name: indexpoh90Q6U4vMeta?.name ?? "meus_eventos-slug-users",
    path: indexpoh90Q6U4vMeta?.path ?? "users",
    meta: indexpoh90Q6U4vMeta || {},
    alias: indexpoh90Q6U4vMeta?.alias || [],
    redirect: indexpoh90Q6U4vMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/meus_eventos/[slug]/users/index.vue").then(m => m.default || m)
  },
  {
    name: venueoiqz1uUXRJMeta?.name ?? "meus_eventos-slug-venue",
    path: venueoiqz1uUXRJMeta?.path ?? "venue",
    meta: venueoiqz1uUXRJMeta || {},
    alias: venueoiqz1uUXRJMeta?.alias || [],
    redirect: venueoiqz1uUXRJMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/meus_eventos/[slug]/venue.vue").then(m => m.default || m)
  }
],
    name: _91slug_93NBPPW4jnsEMeta?.name ?? undefined,
    meta: _91slug_93NBPPW4jnsEMeta || {},
    alias: _91slug_93NBPPW4jnsEMeta?.alias || [],
    redirect: _91slug_93NBPPW4jnsEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/meus_eventos/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexEshe4L3i94Meta?.name ?? "meus_eventos",
    path: indexEshe4L3i94Meta?.path ?? "/meus_eventos",
    meta: indexEshe4L3i94Meta || {},
    alias: indexEshe4L3i94Meta?.alias || [],
    redirect: indexEshe4L3i94Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/meus_eventos/index.vue").then(m => m.default || m)
  },
  {
    name: my_accountR2VGeWjWHNMeta?.name ?? "my_account",
    path: my_accountR2VGeWjWHNMeta?.path ?? "/my_account",
    meta: my_accountR2VGeWjWHNMeta || {},
    alias: my_accountR2VGeWjWHNMeta?.alias || [],
    redirect: my_accountR2VGeWjWHNMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/my_account.vue").then(m => m.default || m)
  },
  {
    name: indexH5AaUUeSy6Meta?.name ?? "onboarding",
    path: indexH5AaUUeSy6Meta?.path ?? "/onboarding",
    meta: indexH5AaUUeSy6Meta || {},
    alias: indexH5AaUUeSy6Meta?.alias || [],
    redirect: indexH5AaUUeSy6Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/onboarding/index.vue").then(m => m.default || m)
  },
  {
    path: campaignsDfCQHEsYkPMeta?.path ?? "/p/:slug()/campaigns",
    children: [
  {
    name: editmoi9t0929fMeta?.name ?? "p-slug-campaigns-campaignId-edit",
    path: editmoi9t0929fMeta?.path ?? ":campaignId()/edit",
    meta: editmoi9t0929fMeta || {},
    alias: editmoi9t0929fMeta?.alias || [],
    redirect: editmoi9t0929fMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/p/[slug]/campaigns/[campaignId]/edit.vue").then(m => m.default || m)
  },
  {
    name: index6fcOqsrXuFMeta?.name ?? "p-slug-campaigns-campaignId",
    path: index6fcOqsrXuFMeta?.path ?? ":campaignId()",
    meta: index6fcOqsrXuFMeta || {},
    alias: index6fcOqsrXuFMeta?.alias || [],
    redirect: index6fcOqsrXuFMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/p/[slug]/campaigns/[campaignId]/index.vue").then(m => m.default || m)
  },
  {
    name: send7wDVBgKFIlMeta?.name ?? "p-slug-campaigns-campaignId-send",
    path: send7wDVBgKFIlMeta?.path ?? ":campaignId()/send",
    meta: send7wDVBgKFIlMeta || {},
    alias: send7wDVBgKFIlMeta?.alias || [],
    redirect: send7wDVBgKFIlMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/p/[slug]/campaigns/[campaignId]/send.vue").then(m => m.default || m)
  },
  {
    name: indexljmFmtnJqIMeta?.name ?? "p-slug-campaigns",
    path: indexljmFmtnJqIMeta?.path ?? "",
    meta: indexljmFmtnJqIMeta || {},
    alias: indexljmFmtnJqIMeta?.alias || [],
    redirect: indexljmFmtnJqIMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/p/[slug]/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: new9Bb5FdVE4MMeta?.name ?? "p-slug-campaigns-new",
    path: new9Bb5FdVE4MMeta?.path ?? "new",
    meta: new9Bb5FdVE4MMeta || {},
    alias: new9Bb5FdVE4MMeta?.alias || [],
    redirect: new9Bb5FdVE4MMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/p/[slug]/campaigns/new.vue").then(m => m.default || m)
  }
],
    name: campaignsDfCQHEsYkPMeta?.name ?? undefined,
    meta: campaignsDfCQHEsYkPMeta || {},
    alias: campaignsDfCQHEsYkPMeta?.alias || [],
    redirect: campaignsDfCQHEsYkPMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/p/[slug]/campaigns.vue").then(m => m.default || m)
  },
  {
    name: _91contactListSlug_93lWDlr6Gd2mMeta?.name ?? "p-slug-contact_lists-contactListSlug",
    path: _91contactListSlug_93lWDlr6Gd2mMeta?.path ?? "/p/:slug()/contact_lists/:contactListSlug()",
    meta: _91contactListSlug_93lWDlr6Gd2mMeta || {},
    alias: _91contactListSlug_93lWDlr6Gd2mMeta?.alias || [],
    redirect: _91contactListSlug_93lWDlr6Gd2mMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/p/[slug]/contact_lists/[contactListSlug].vue").then(m => m.default || m)
  },
  {
    name: indexUMVFZD2I04Meta?.name ?? "p-slug-contact_lists",
    path: indexUMVFZD2I04Meta?.path ?? "/p/:slug()/contact_lists",
    meta: indexUMVFZD2I04Meta || {},
    alias: indexUMVFZD2I04Meta?.alias || [],
    redirect: indexUMVFZD2I04Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/p/[slug]/contact_lists/index.vue").then(m => m.default || m)
  },
  {
    name: editXIVbAO1LNGMeta?.name ?? "p-slug-edit",
    path: editXIVbAO1LNGMeta?.path ?? "/p/:slug()/edit",
    meta: editXIVbAO1LNGMeta || {},
    alias: editXIVbAO1LNGMeta?.alias || [],
    redirect: editXIVbAO1LNGMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/p/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    path: guestlistsfTSphu4R1VMeta?.path ?? "/p/:slug()/guestlists",
    children: [
  {
    name: editCxWmwn5ZkuMeta?.name ?? "p-slug-guestlists-guestlistId-edit",
    path: editCxWmwn5ZkuMeta?.path ?? ":guestlistId()/edit",
    meta: editCxWmwn5ZkuMeta || {},
    alias: editCxWmwn5ZkuMeta?.alias || [],
    redirect: editCxWmwn5ZkuMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/p/[slug]/guestlists/[guestlistId]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexsL6R3wPZORMeta?.name ?? "p-slug-guestlists-guestlistId",
    path: indexsL6R3wPZORMeta?.path ?? ":guestlistId()",
    meta: indexsL6R3wPZORMeta || {},
    alias: indexsL6R3wPZORMeta?.alias || [],
    redirect: indexsL6R3wPZORMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/p/[slug]/guestlists/[guestlistId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexDyHHDt0pBNMeta?.name ?? "p-slug-guestlists",
    path: indexDyHHDt0pBNMeta?.path ?? "",
    meta: indexDyHHDt0pBNMeta || {},
    alias: indexDyHHDt0pBNMeta?.alias || [],
    redirect: indexDyHHDt0pBNMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/p/[slug]/guestlists/index.vue").then(m => m.default || m)
  },
  {
    name: new3LWjwTB37pMeta?.name ?? "p-slug-guestlists-new",
    path: new3LWjwTB37pMeta?.path ?? "new",
    meta: new3LWjwTB37pMeta || {},
    alias: new3LWjwTB37pMeta?.alias || [],
    redirect: new3LWjwTB37pMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/p/[slug]/guestlists/new.vue").then(m => m.default || m)
  }
],
    name: guestlistsfTSphu4R1VMeta?.name ?? undefined,
    meta: guestlistsfTSphu4R1VMeta || {},
    alias: guestlistsfTSphu4R1VMeta?.alias || [],
    redirect: guestlistsfTSphu4R1VMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/p/[slug]/guestlists.vue").then(m => m.default || m)
  },
  {
    name: indextg33DI1USAMeta?.name ?? "p-slug",
    path: indextg33DI1USAMeta?.path ?? "/p/:slug()",
    meta: indextg33DI1USAMeta || {},
    alias: indextg33DI1USAMeta?.alias || [],
    redirect: indextg33DI1USAMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/p/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: _91guestlistId_93dnnBRI1DKFMeta?.name ?? "p-slug-listas-guestlistId",
    path: _91guestlistId_93dnnBRI1DKFMeta?.path ?? "/p/:slug()/listas/:guestlistId()",
    meta: _91guestlistId_93dnnBRI1DKFMeta || {},
    alias: _91guestlistId_93dnnBRI1DKFMeta?.alias || [],
    redirect: _91guestlistId_93dnnBRI1DKFMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/p/[slug]/listas/[guestlistId].vue").then(m => m.default || m)
  },
  {
    name: indexZETROZKIcTMeta?.name ?? "producer_wizard",
    path: indexZETROZKIcTMeta?.path ?? "/producer_wizard",
    meta: indexZETROZKIcTMeta || {},
    alias: indexZETROZKIcTMeta?.alias || [],
    redirect: indexZETROZKIcTMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/producer_wizard/index.vue").then(m => m.default || m)
  },
  {
    name: new3aF4rqkogXMeta?.name ?? "producer_wizard-new",
    path: new3aF4rqkogXMeta?.path ?? "/producer_wizard/new",
    meta: new3aF4rqkogXMeta || {},
    alias: new3aF4rqkogXMeta?.alias || [],
    redirect: new3aF4rqkogXMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/producer_wizard/new.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OsOWxVXvk9Meta?.name ?? "producer_wizard-success-slug",
    path: _91slug_93OsOWxVXvk9Meta?.path ?? "/producer_wizard/success/:slug()",
    meta: _91slug_93OsOWxVXvk9Meta || {},
    alias: _91slug_93OsOWxVXvk9Meta?.alias || [],
    redirect: _91slug_93OsOWxVXvk9Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/producer_wizard/success/[slug].vue").then(m => m.default || m)
  }
]